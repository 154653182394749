import { DTOAdapter } from '@services/DTOAdapter'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { Organisation } from '@app/domain/Organisation'
import { OrganisationDTO } from '@services/DTO/OrganisationDTO'
import { PatchRequestBody } from '@services/DTO/PatchRequestBody'
import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    get(): Observable<Organisation> {
        //TODO Add Cache logic
        const url = new URL('/organisation', environment.apiBaseURL)
        return this.http.get<OrganisationDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptOrganisationDto(dto))
            )
    }

    patch(body: PatchRequestBody): Observable<Organisation> {
        const url = `${environment.apiBaseURL}/organisation`

        return this.http.patch<OrganisationDTO>(
            url,
            body
        )
            .pipe(
                map(dto => this.dtoAdapter.adaptOrganisationDto(dto))
            )
    }
}
